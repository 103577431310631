<template>
  <div class="searchHistory">
    <h3>
      <span>搜索历史</span>
      <img src="../../assets/images/knowledge/icon_delete.png" @click="clearHistory" alt=""/>
    </h3>
    <ul>
      <li
        v-for="(item, index) in historyList"
        :key="index"
        @click="clickHistoryItem(item)"
      >{{item}}</li>
    </ul>
  </div>
</template>

<script>
// import { } from 'vuex'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'SearchHistory',
  components: {},
  props: [],
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters(['historyList'])
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    ...mapMutations({
      'SET_HISTORY_LIST': 'knowledge/SET_HISTORY_LIST',
      'UPDATE_HISTORY_LIST': 'knowledge/UPDATE_HISTORY_LIST'
    }),
    clickHistoryItem(item) {
      this.$emit('clickHistoryItem', item)
    },
    clearHistory() {
      this.SET_HISTORY_LIST([])
    }
  }

}
</script>

<style lang="less" scoped>
.searchHistory{
  padding: .41rem .27rem;
  background: #fff;
  height: calc(100vh - .76rem - .86rem);
  box-sizing: border-box;
  h3{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: .24rem;
    line-height: .34rem;
    color: #999;
    font-weight: normal;
    margin-bottom: .27rem;
    img{
      width: .24rem;
      height: .24rem;
    }
  }
  ul{
    display: flex;
    flex-wrap: wrap;
    height: 2rem;
    font-size: .2rem;
    overflow: hidden;
    li{
      padding: .1rem .27rem .09rem;
      height: .29rem;
      line-height: .29rem;
      background: #F5F5F5;
      border-radius: .24rem;
      margin: 0 .2rem .2rem 0;
    }
  }
}
</style>

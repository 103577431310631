<template>
  <div id="knowledgeDetail">
    <div class="page_title ellipsis">
      <img src="../../assets/images/detail/left_back.png" alt @click="clickBack">
      <span>{{ currentFolderItem.title || currentCategoryItem.name }}</span>
    </div>
    <div class="searchCom">
      <div class="grey9 inputCom">
        <input
          v-model="searchValue"
          type="text"
          id="input"
          placeholder="输入关键字进行搜索"
          @focus="isShowSearchHistory = true"
          @keyup.enter="confirmSearch(searchValue)"
        >
        <div v-show="searchValue" class="closeBtn" @click="confirmSearch('')"></div>
      </div>
      <div v-if="isShowSearchHistory" class="" @click="isShowSearchHistory = false">取消</div>
      <template v-else>
<!--        <div v-if="isFolder || code === '2004'" class="primary" @click="showPopup">筛选</div>-->
        <div v-if="isFolder" class="primary" @click="showPopup">筛选</div>
      </template>
    </div>
    <div class="contentCom">
      <template v-if="!isShowSearchHistory">
        <ul v-if="isFolder" class="folderList">
          <li
            v-for="(item, index) in folderList"
            :key="index"
            @click="clickFolderItem(item)"
          >
            <img src="../../assets/images/knowledge/icon_folder.png" alt="">
            <p class="ellipsis">{{ item.title }}</p>
          </li>
        </ul>
        <ul v-else class="folderList">
          <li
            v-for="(item, index) in fileList"
            :key="index"
            @click="openFile(item)"
          >
            <img src="../../assets/images/knowledge/icon_file.png" alt="">
            <p class="ellipsis">{{ item.file_name }}</p>
          </li>
        </ul>
        <div v-if="(isFolder && folderList.length === 0) || (!isFolder && fileList.length === 0)" class="noData">
          <img src="../../assets/images/knowledge/no_data.png" alt="">
          <p>~无搜索结果~</p>
        </div>
      </template>
      <search-history
        v-else
        ref="searchHistory"
        @clickHistoryItem="confirmSearch($event)"
      />
    </div>
    <filter-popup
      ref="filterPopup"
      :current-category-item="currentCategoryItem"
      :technology-list="technologyList"
      @confirmFilter="confirmFilter"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import FilterPopup from '@/views/knowledge/filterPopup'
import port from '@/api/port'
import SearchHistory from '@/views/knowledge/searchHistory'
export default {
  name: 'Knowledge',
  components: { SearchHistory, FilterPopup },
  props: [],
  data() {
    return {
      searchValue: '',
      folderList: [],
      fileList: [],
      isShowSearchHistory: false,
      isFolder: true,
      currentFolderItem: {},
      technologyList: []
    }
  },
  computed: {
    ...mapGetters(['categories', 'categoryObj']),
    code() {
      return this.$route.params.code
    },
    currentCategoryItem() {
      return this.categoryObj ? this.categoryObj[this.code] : {}
    }
  },
  watch: {},
  created() {
    this.getKnowledgeCategoryObj().then(() => {
      const technologyList = this.categoryObj[2003].children
      this.technologyList = Object.values(technologyList)
      // this.isFolder = this.currentCategoryItem.list === 'folder'
      this.getKnowledgeFolder()
    })
  },
  mounted() {},
  methods: {
    ...mapActions({
      'getKnowledgeCategoryObj': 'knowledge/getKnowledgeCategoryObj'
    }),
    ...mapMutations({
      'UPDATE_HISTORY_LIST': 'knowledge/UPDATE_HISTORY_LIST'
    }),
    clickBack() {
      // if (this.isFolder || this.code === '2004') {
      if (this.isFolder) {
        this.$router.back()
      } else {
        this.isFolder = true
        this.currentFolderItem = {}
      }
    },
    showPopup() {
      this.$refs.filterPopup.show()
    },
    // 获取文件夹列表
    getKnowledgeFolder(params = {}) {
      return new Promise((resolve, reject) => {
        port.getKnowledgeFolder({
          module: this.currentCategoryItem.code,
          title: this.searchValue,
          limit: 100,
          ...params
        }).then(res => {
          this.folderList = res.data
          resolve(res.data)
        })
      })
    },
    // 获取文件列表
    getKnowledgeFile(params = {}) {
      port.getKnowledgeFile({
        id: this.currentFolderItem.id,
        ...params
      }).then(res => {
        if (this.searchValue) {
          this.fileList = res.data.filter(item => {
            return item.file_name.includes(this.searchValue)
          })
        } else {
          this.fileList = res.data
        }
      })
    },
    confirmSearch(searchValue = '') {
      this.searchValue = searchValue
      if (searchValue) {
        this.UPDATE_HISTORY_LIST(this.searchValue)
        document.getElementById('input').blur()
      }
      if (this.isFolder) {
        this.getKnowledgeFolder()
      } else {
        this.getKnowledgeFile()
      }
      this.isShowSearchHistory = false
    },
    confirmFilter({ code, filterCategoryItemObj }) {
      // this.$route.params.code = code
      if (code !== this.code) {
        this.$router.replace({ params: { code }})
      }
      const params = {}
      const values = Object.values(filterCategoryItemObj)
      values.forEach(item => {
        if (item.ids.length > 0) {
          params[item.paramsKey] = item.ids[item.ids.length - 1]
        }
      })
      this.getKnowledgeFolder(params)
      // this.isFolder = this.currentCategoryItem.list === 'folder'
      // if (this.isFolder) {
      //   this.getKnowledgeFolder(params)
      // } else {
      //   if (code === '2004') {
      //     this.getKnowledgeFolder().then((folderList) => {
      //       this.currentFolderItem = folderList[0]
      //       this.getKnowledgeFile(params)
      //     })
      //   } else {
      //     this.getKnowledgeFile(params)
      //   }
      // }

      // if (code === '2004') {
      //   this.getKnowledgeFolder().then((folderList) => {
      //     this.clickFolderItem(folderList[0])
      //   })
      // } else {
      //
      // }
      // if (this.isFolder) {
      //   this.getKnowledgeFolder(params)
      // } else {
      //   this.getKnowledgeFile(params)
      // }
    },
    clickFolderItem(item) {
      this.currentFolderItem = item
      this.getKnowledgeFile()
      this.isFolder = false
      this.searchValue = ''
    },
    openFile(item) {
      console.log(item)
      location.href = item.file_url
    }
  }

}
</script>

<style lang="less" scoped>
#knowledgeDetail{
  padding-top: .75rem;
  .contentCom{
    background: #fff;
    height: calc(100vh - .76rem - .86rem);
  }
  .folderList{
    li{
      padding: .24rem .27rem;
      background: #fff;
      border-bottom: .01rem #F8F8F8 solid;
      display: flex;
      img{
        width: .38rem;
        height: .38rem;
        margin-right: .07rem;
      }
      p{
        flex: 1;
      }
    }
  }

  .noData{
    text-align: center;
    padding-top: 1.3rem;
    img{
      width: 3.41rem;
      height: 2.39rem;
      margin-bottom: .14rem;
    }
    p{
      color: #999999;
      font-size: .24rem;
      line-height: .34rem;
    }
  }

  .searchCom{
    padding: .15rem .27rem;
    background: #fff;
    margin-bottom: .02rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: .24rem;
    .inputCom{
      width: 5.1rem;
      height: .54rem;
      border-radius: .27rem;
      border: .01rem solid #D8D8D8;
      box-sizing: border-box;
      padding: 0 .68rem;
      background: url("../../assets/images/knowledge/icon_search.png") no-repeat .15rem center;
      background-size: .48rem;
      position: relative;
      input{
        width: 100%;
        height: .5rem;
        box-sizing: border-box;
        color: #333;
      }
      .closeBtn{
        background: url("../../assets/images/close.png") no-repeat center center;
        background-size: .32rem;
        width: .48rem;
        height: .48rem;
        position: absolute;
        right: .1rem;
        top: .01rem
      }
    }
  }
}
</style>

<template>
  <div id="filterPopup">
    <van-popup
      v-model="isShowPopup"
      position="right"
    >
      <section class="filterPopupInner">
        <template v-if="!isShowFilterCom">
          <div class="categoryList">
            <h3>
              栏目分类
            </h3>
            <ul>
              <li
                v-for="(item, index) in categories"
                :key="item.code"
                :class="{'active': item.code === currentCode}"
                @click="changeCurrentCode(item.code)"
              >
                {{ item.name }}
              </li>
            </ul>
          </div>
          <div class="filterCategoryList">
            <h3>
              筛选信息
              <div class="resetBtn" @click="resetFilter">重置</div>
            </h3>
            <ul>
              <li
                v-for="(item, key) in filterCategoryItemObj"
                :key="key"
                @click="showFilterCom(item, key)"
              >
                <span>{{ item.name }}</span>
                <i>{{ item.value }}</i>
              </li>
            </ul>
          </div>

          <footer-btn
            :btn-num="2"
            cancel-text="取消"
            confirm-text="确定筛选"
            @cancel="isShowPopup = false"
            @confirm="confirmFilter"
          />
        </template>

        <template v-else>
          <!--车辆型号-->
          <section v-if="currentFilterCategoryKey === 'car'" class="filterListWrap">
            <div class="topCom">
              <div>
                <template v-for="(value, valueIndex) in getValues(filterCategoryItemObj.car.value)">
                  <span
                    :key="valueIndex"
                    :class="{ 'active': valueIndex < filterCategoryItemObj.car.ids.length }"
                    @click="clickValues(valueIndex)"
                  >{{ value }}</span>
                  <img
                    v-if="filterCategoryItemObj.car.ids.length > 0 &&
                      valueIndex < 3 &&
                      value !== '全部车系' &&
                      value !== '全部车型'"
                    :key="'valueIndex'+valueIndex"
                    src="../../assets/images/right-icon.png"
                    alt=""
                  >
                </template>
                <span v-if="filterCategoryItemObj.car.ids.length === 0">品牌</span>
                <span v-if="filterCategoryItemObj.car.ids.length === 1">
                  {{ filterCategoryItemObj.car.value.includes('全部车系') ? '' : '车系' }}
                </span>
                <span v-if="filterCategoryItemObj.car.ids.length === 2">
                  {{ filterCategoryItemObj.car.value.includes('全部车型') ? '' : '车型' }}
                </span>
                <span v-if="filterCategoryItemObj.car.ids.length === 3">
                  {{ filterCategoryItemObj.car.value.includes('全部年款') ? '' : '年款' }}
                </span>
              </div>
              <div @click="clearItemFilter">清空</div>
            </div>
            <div class="filterList">
              <template v-if="filterCategoryItemObj.car.ids.length > 0 && carList.length === 0">
                <p class="noData">暂无可选信息，请更换筛选条件</p>
              </template>
              <template v-else>
                <div
                  v-if="filterCategoryItemObj.car.ids.length === 1"
                  class="item"
                  :class="{'active': filterCategoryItemObj.car.value.includes('全部车系')}"
                  @click="clickAllCar('全部车系')"
                >全部车系</div>
                <div
                  v-if="filterCategoryItemObj.car.ids.length === 2"
                  class="item"
                  :class="{'active': filterCategoryItemObj.car.value.includes('全部车型')}"
                  @click="clickAllCar('全部车型')"
                >全部车型</div>
                <div
                  v-if="filterCategoryItemObj.car.ids.length >= 3"
                  class="item"
                  :class="{'active': filterCategoryItemObj.car.value.includes('全部年款')}"
                  @click="clickAllCar('全部年款')"
                >全部年款</div>
                <mt-index-list>
                  <mt-index-section
                    v-for="(item, key) in carList"
                    :key="key"
                    :index="key"
                  >
                    <div
                      v-for="child in item"
                      :key="child.id"
                      class="item"
                      :class="{'active': filterCategoryItemObj.car.ids.includes(child.id)}"
                      @click="clickItem(child)"
                    >
                      {{ child.name }}
                    </div>
                  </mt-index-section>
                </mt-index-list>
              </template>

            </div>
          </section>

          <!--系统-->
          <section v-if="currentFilterCategoryKey === 'system'" class="filterListWrap">
            <div class="topCom">
              <div>
                <span>系统</span>
              </div>
              <div @click="clearItemFilter">清空</div>
            </div>
            <div class="filterList">
              <mt-index-list>
                <mt-index-section
                  v-for="(item, key) in systemList"
                  :key="key"
                  :index="key"
                >
                  <div
                    v-for="child in item"
                    :key="child.id"
                    class="item"
                    :class="{'active': filterCategoryItemObj.system.ids.includes(child.id)}"
                    @click="clickItem(child)"
                  >
                    {{ child.name }}
                  </div>
                </mt-index-section>
              </mt-index-list>

            </div>
          </section>

          <!--技术信息-->
          <section v-if="currentFilterCategoryKey === 'technology'" class="filterListWrap">
            <div class="topCom">
              <div>
                <span>技术信息分类</span>
              </div>
              <div @click="clearItemFilter">清空</div>
            </div>
            <div class="filterList">
              <div
                v-for="child in technologyList"
                :key="child.id"
                class="item"
                :class="{'active': filterCategoryItemObj.technology.ids.includes(child.id)}"
                @click="clickItem(child)"
              >
                {{ child.name }}
              </div>
            </div>
          </section>

          <!--发动机-->
          <section v-if="currentFilterCategoryKey === 'engine'" class="filterListWrap">
            <div class="topCom">
              <div>
                <template v-for="(value, valueIndex) in getValues(filterCategoryItemObj.engine.value)">
                  <span
                    :key="valueIndex"
                    :class="{ 'active': valueIndex < filterCategoryItemObj.engine.ids.length }"
                    @click="clickValues(valueIndex)"
                  >{{ value }}</span>
                  <img
                    v-if="filterCategoryItemObj.engine.ids.length > 0 && valueIndex < 1"
                    :key="'valueIndex'+valueIndex"
                    src="../../assets/images/right-icon.png"
                    alt=""
                  >
                </template>
                <span v-if="filterCategoryItemObj.engine.ids.length === 0">发动机类型</span>
                <span v-if="filterCategoryItemObj.engine.ids.length === 1">发动机型号</span>
              </div>
              <div @click="clearItemFilter">清空</div>
            </div>
            <div class="filterList">
              <mt-index-list>
                <mt-index-section
                  v-for="(item, key) in engineList"
                  :key="key"
                  :index="key"
                >
                  <div
                    v-for="child in item"
                    :key="child.id"
                    class="item"
                    :class="{'active': filterCategoryItemObj.engine.ids.includes(child.id)}"
                    @click="clickItem(child)"
                  >
                    {{ child.name }}
                  </div>
                </mt-index-section>
              </mt-index-list>

            </div>
          </section>

          <!--变速箱-->
          <section v-if="currentFilterCategoryKey === 'gearbox'" class="filterListWrap">
            <div class="topCom">
              <div>
                <template v-for="(value, valueIndex) in getValues(filterCategoryItemObj.gearbox.value)">
                  <span
                    :key="valueIndex"
                    :class="{ 'active': valueIndex < filterCategoryItemObj.gearbox.ids.length }"
                    @click="clickValues(valueIndex)"
                  >{{ value }}</span>
                  <img
                    v-if="filterCategoryItemObj.gearbox.ids.length > 0 && valueIndex < 1"
                    :key="'valueIndex'+valueIndex"
                    src="../../assets/images/right-icon.png"
                    alt=""
                  >
                </template>
                <span v-if="filterCategoryItemObj.gearbox.ids.length === 0">变速箱类型</span>
                <span v-if="filterCategoryItemObj.gearbox.ids.length === 1">变速箱型号</span>
              </div>
              <div @click="clearItemFilter">清空</div>
            </div>
            <div class="filterList">
              <mt-index-list>
                <mt-index-section
                  v-for="(item, key) in gearboxList"
                  :key="key"
                  :index="key"
                >
                  <div
                    v-for="child in item"
                    :key="child.id"
                    class="item"
                    :class="{'active': filterCategoryItemObj.gearbox.ids.includes(child.id)}"
                    @click="clickItem(child)"
                  >
                    {{ child.name }}
                  </div>
                </mt-index-section>
              </mt-index-list>

            </div>
          </section>

          <footer-btn
            :btn-num="1"
            class-name="cancel"
            confirm-text="关闭"
            @confirm="closeFilterCom"
          />
        </template>
      </section>
    </van-popup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FooterBtn from '@/components/footerBtn'
import port from '@/api/port'

export default {
  name: 'FilterPopup',
  components: { FooterBtn },
  props: {
    currentCategoryItem: {
      type: Object,
      default: () => {
        return {}
      }
    },
    technologyList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    // cat_id	否	int	信息分类id
    // car_dict_id	否	int	车型字典id
    // part_dict_id	否	int	（系统）部件字典id
    // engine_dict_id	否	int	发动机字典id
    // gearbox_dict_id	否	int	变速箱字典id
    const obj = {
      car: {
        name: '车辆型号',
        value: '',
        maxLevel: 4,
        paramsKey: 'car_dict_id',
        ids: []
      },
      system: {
        name: '系统',
        value: '',
        maxLevel: 1,
        paramsKey: 'part_dict_id',
        ids: []
      }
    }
    const filterCategoryObj = {
      2001: { // 工具设备
        ...obj
      },
      2002: { // 维修工艺
        ...obj
      },
      2003: { // 技术信息
        ...obj,
        technology: {
          name: '技术信息分类',
          value: '',
          maxLevel: 1,
          paramsKey: 'cat_id',
          ids: []
        }
      },
      2004: { // 维修数据
        car: obj.car,
        engine: {
          name: '发动机信息',
          value: '',
          maxLevel: 2,
          paramsKey: 'engine_dict_id',
          ids: []
        },
        gearbox: {
          name: '变速箱信息',
          value: '',
          maxLevel: 2,
          paramsKey: 'gearbox_dict_id',
          ids: []
        }
      }

    }
    return {
      isShowPopup: false,
      isShowFilterCom: false,
      currentCode: '',
      filterCategoryObj,
      carList: {},
      systemList: {},
      engineList: {},
      gearboxList: {},
      currentFilterCategoryKey: '',
      oldValueObj: {
        value: '',
        ids: []
      }
    }
  },
  computed: {
    ...mapGetters(['categories', 'categoryObj']),
    filterCategoryItemObj() {
      return this.filterCategoryObj[this.currentCode]
    }
    // filterCarList() {
    //   const car = this.filterCategoryItemObj.car
    //   const level = car.ids.length
    //   let arr = level > 0 ? this.filterList(this.carList, car.ids) : this.carList
    //   console.log(arr)
    //   return arr
    // },
    // filterEngineList() {
    //   const engine = this.filterCategoryItemObj.engine
    //   const level = engine.ids.length
    //   let arr = level > 0 ? this.filterList(this.engineList, engine.ids) : this.engineList
    //   console.log(arr)
    //   return arr
    // },
    // filterGearboxList() {
    //   const gearbox = this.filterCategoryItemObj.gearbox
    //   const level = gearbox.ids.length
    //   let arr = level > 0 ? this.filterList(this.gearboxList, gearbox.ids) : this.gearboxList
    //   console.log(arr)
    //   return arr
    // }
  },
  watch: {},
  created() {
    // this.getCarList()
    // this.getSystemList()
    // this.getEngineList()
    // this.getGearboxList()
  },
  mounted() {},
  methods: {
    getCarList(parent_id = '0') {
      port.getDict({
        type: 'car',
        parent_id
      }).then(res => {
        this.carList = res.data
      })
    },
    getSystemList(parent_id = '0') {
      port.getDict({
        type: 'fault',
        parent_id
      }).then(res => {
        this.systemList = res.data
      })
    },
    getEngineList(parent_id = '0') {
      port.getDict({
        type: 'engine',
        parent_id
      }).then(res => {
        this.engineList = res.data
      })
    },
    getGearboxList(parent_id = '0') {
      port.getDict({
        type: 'gearbox',
        parent_id
      }).then(res => {
        this.gearboxList = res.data
      })
    },
    filterList(obj, ids, index = 0) {
      console.log(obj, ids, index)
      const id = ids[index]
      const values = Object.values(obj).reduce((a, b) => { return a.concat(b) })
      console.log(values)
      const item = values.find(item => {
        return item.id === id
      })
      index += 1
      if (ids[index]) {
        return this.filterList(item.children, ids, index)
      } else {
        const key = this.currentFilterCategoryKey
        const filterCategoryItem = this.filterCategoryObj[this.currentCode][key]
        return filterCategoryItem.maxLevel === ids.length ? obj : item.children
      }
    },
    show() {
      this.currentCode = this.currentCategoryItem.code
      this.isShowPopup = true
    },
    changeCurrentCode(code) {
      this.resetFilter()
      this.currentCode = code
    },
    // 显示对应分类的筛选列表
    showFilterCom(filterCategoryItem, key) {
      console.log(filterCategoryItem)
      this.currentFilterCategoryKey = key
      this.oldValueObj.value = filterCategoryItem.value
      this.oldValueObj.ids = [...filterCategoryItem.ids]
      if (filterCategoryItem.ids.length === filterCategoryItem.maxLevel) {
        this.getList(filterCategoryItem.ids[filterCategoryItem.ids.length - 2], key)
      } else {
        this.getList(filterCategoryItem.ids[filterCategoryItem.ids.length - 1], key)
      }
      this.isShowFilterCom = true
    },
    clickItem(item) {
      const key = this.currentFilterCategoryKey
      const filterCategoryItem = this.filterCategoryObj[this.currentCode][key]
      filterCategoryItem.value = item.fullname || item.name
      if (filterCategoryItem.ids.length < filterCategoryItem.maxLevel) {
        // 非最后一级 push id
        filterCategoryItem.ids.push(item.id)
      } else {
        // 最后一级 替换最后的id
        filterCategoryItem.ids.splice(filterCategoryItem.maxLevel - 1, 1, item.id)
      }
      // 最后一级 关闭筛选弹窗
      if (filterCategoryItem.ids.length === filterCategoryItem.maxLevel) {
        this.isShowFilterCom = false
      } else {
        this.getList(item.id)
      }
    },
    clickValues(valueIndex) {
      const key = this.currentFilterCategoryKey
      const filterCategoryItem = this.filterCategoryObj[this.currentCode][key]
      const ids = filterCategoryItem.ids.slice(0, valueIndex)
      filterCategoryItem.ids = ids
      this.getList(ids[ids.length - 1])
      const values = this.getValues(filterCategoryItem.value).slice(0, valueIndex)
      filterCategoryItem.value = values.join('/')
    },
    getValues(value) {
      return value ? value.split('/') : []
    },
    getList(id, key = this.currentFilterCategoryKey) {
      switch (key) {
        case 'car':
          this.getCarList(id)
          break
        case 'system':
          this.getSystemList(id)
          break
        case 'engine':
          this.getEngineList(id)
          break
        case 'gearbox':
          this.getGearboxList(id)
          break
      }
    },
    clearItemFilter() {
      const key = this.currentFilterCategoryKey
      const filterCategoryItem = this.filterCategoryObj[this.currentCode][key]
      filterCategoryItem.value = ''
      filterCategoryItem.ids = []
      this.isShowFilterCom = false
      this.getList('0')
    },
    clickAllCar(str) {
      const key = this.currentFilterCategoryKey
      const filterCategoryItem = this.filterCategoryObj[this.currentCode][key]
      if(filterCategoryItem.value.indexOf('全部') === -1) {
        if(filterCategoryItem.ids.length === filterCategoryItem.maxLevel) {
          this.clickValues(filterCategoryItem.maxLevel - 1)
        }
        filterCategoryItem.value += '/' + str
      }
      this.isShowFilterCom = false
    },
    closeFilterCom() {
      const key = this.currentFilterCategoryKey
      const filterCategoryItem = this.filterCategoryObj[this.currentCode][key]
      console.log()
      if (filterCategoryItem.ids.length < filterCategoryItem.maxLevel) {
        filterCategoryItem.value = this.oldValueObj.value
        filterCategoryItem.ids = this.oldValueObj.ids
      }
      this.isShowFilterCom = false
    },
    // 重置
    resetFilter() {
      for (const key in this.filterCategoryObj[this.currentCode]) {
        const item = this.filterCategoryObj[this.currentCode][key]
        if (item.ids.length > 0) {
          item.ids = []
          item.value = ''
          this.getList('0', key)
        }
      }
    },
    // 确认筛选
    confirmFilter() {
      this.$emit('confirmFilter', {
        code: this.currentCode,
        filterCategoryItemObj: this.filterCategoryItemObj
      })
      this.isShowPopup = false
    }
  }

}
</script>

<style lang="less" scoped>
#filterPopup{
  position: relative;
  .filterPopupInner{
    width: 5.58rem;
    height: 100vh;
    overflow-y: hidden;
    background: #F3F3F3;
    h3{
      padding: .25rem .27rem .25rem .82rem;
      font-size: .24rem;
      line-height: .34rem;
      background: url("../../assets/images/knowledge/icon_title_01.png") no-repeat .27rem center ;
      background-size: .38rem;
    }
    // 栏目分类
    .categoryList{
      background: #fff;
      padding-bottom: .11rem;
      ul{
        display: flex;
        flex-wrap: wrap;
      }
      li{
        width: 1.85rem;
        height: .44rem;
        line-height: .44rem;
        border-radius: .24rem;
        border: .02rem solid #D8D8D8;
        color: #999999;
        text-align: center;
        font-size: .2rem;
        margin: 0 .44rem .2rem;
      }
      .active{
        color: #fff;
        background: #2F8AF1;
        border-color: #2F8AF1;
      }
    }
    // 筛选分类列表
    .filterCategoryList{
      h3{
        background-image: url("../../assets/images/knowledge/icon_title_02.png") ;
        .resetBtn{
          background: url("../../assets/images/knowledge/icon_title_03.png") no-repeat left center ;
          background-size: .24rem;
          float: right;
          padding-left: .34rem;
          color: #999;
          font-weight: normal;
        }
      }
      ul{
        li{
          padding: .26rem .65rem .26rem .27rem;
          line-height: .34rem;
          font-size: .24rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: #fff url("../../assets/images/right-icon.png") no-repeat 5rem center ;
          background-size: .31rem;
          margin-bottom: .14rem;
          i{
            font-style: normal;
            color: #999;
          }
        }
      }
    }

    //筛选列表
    .filterListWrap{
      .topCom{
        height: .38rem;
        line-height: .38rem;
        padding: .26rem .27rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #fff;
        font-size: .24rem;
        div{
          display: flex;
          align-items: center;
        }
        img{
          width: .2rem;
          margin-right:.07rem;
        }
        span{
          margin-right:.07rem;
          &.active{
            color: #2f8af1;
          }
        }
      }
      .filterList{
        line-height: .34rem;
        .item{
          padding: .1rem .27rem;
          background: #fff;
          font-size: .24rem;
          margin-bottom: .02rem;
          &.active{
            color: #2F8AF1;
          }
        }
      }
    }
  }

  .noData{
    font-size: .24rem;
    color: #999;
    text-align: center;
    padding: 1rem 0;
  }
}

</style>
<style lang="less">
  #filterPopup{
    /*.mint-indexlist{*/
    /*  height: calc(100% - 7.5rem - 8.5rem);*/
    /*  overflow-y: auto;*/
    /*}*/
    .mint-indexlist-content{
      padding-bottom: 1rem;
      box-sizing: border-box;
    }
    .mint-indexsection-index{
      padding: .1rem .27rem;
      background: transparent;
      font-size: .24rem;
      color: #666;
    }
    .van-popup{
      overflow-y: hidden;
    }
  }
</style>
